import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'bootstrap';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/slider';
// import './owl.carousel.min';
// import './magnific-popup.min';
// import 'jquery-waypoints/waypoints.js';
// import './parallax';
// import './jquery.dd.min';
// import './jquery.countdown.min';
// import './jquery.counterup.min';
// import './jquery.parallax-scroll';
// import './jquery.elevatezoom';
// import './jquery.fitvids';
// import './imagesloaded.pkgd.min';
// import './isotope.min';
// import './js.cookie';
import './scripts';
// import './shop-quick-view';

import.meta.glob([
    '../images/**',
    '../fonts/**',
]);
